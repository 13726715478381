import { SVGProps, memo } from "react";

function Blog(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <rect width={24} height={24} rx={12} fill="#00DDB3" />
            <path
                d="M9.202 9.253a1.505 1.505 0 00-1.016.97L6 16.78l.346.346 3.495-3.518c-.05-.146-.111-.31-.111-.483a1.125 1.125 0 111.125 1.125c-.173 0-.337-.042-.484-.112l-3.518 3.495.366.366 6.559-2.185c.472-.159.835-.518.97-1.017l1.002-3.548-2.98-2.98-3.568.983zm8.47-1.514l-1.41-1.41a1.128 1.128 0 00-1.593 0l-1.325 1.327 2.98 2.98 1.327-1.325c.462-.419.462-1.133.021-1.572z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoBlog = memo(Blog);
export default MemoBlog;
