import { SVGProps, memo } from "react";

function Solana(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx={8} cy={8} r={8} fill="#fff" />
            <path
                d="M4.96 9.895a.3.3 0 01.212-.088h7.332c.133 0 .2.162.106.257l-1.449 1.448a.3.3 0 01-.212.088H3.617a.15.15 0 01-.106-.257L4.96 9.895z"
                fill="url(#prefix__paint0_linear_536_17432)"
            />
            <path
                d="M4.96 4.488a.309.309 0 01.212-.088h7.332c.133 0 .2.161.106.256L11.16 6.104a.3.3 0 01-.212.088H3.617a.15.15 0 01-.106-.256L4.96 4.488z"
                fill="url(#prefix__paint1_linear_536_17432)"
            />
            <path
                d="M11.161 7.174a.3.3 0 00-.212-.088H3.617a.15.15 0 00-.106.256L4.96 8.791a.3.3 0 00.213.087h7.332a.15.15 0 00.106-.256L11.16 7.174z"
                fill="url(#prefix__paint2_linear_536_17432)"
            />
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_536_17432"
                    x1={-13.104}
                    y1={3.04}
                    x2={-13.299}
                    y2={12.855}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00FFA3" />
                    <stop offset={1} stopColor="#DC1FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_536_17432"
                    x1={-15.322}
                    y1={1.881}
                    x2={-15.517}
                    y2={11.697}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00FFA3" />
                    <stop offset={1} stopColor="#DC1FFF" />
                </linearGradient>
                <linearGradient
                    id="prefix__paint2_linear_536_17432"
                    x1={-14.22}
                    y1={2.457}
                    x2={-14.415}
                    y2={12.272}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00FFA3" />
                    <stop offset={1} stopColor="#DC1FFF" />
                </linearGradient>
            </defs>
        </svg>
    );
}

const MemoSolana = memo(Solana);
export default MemoSolana;
