import MemoAvalanche from "@assets/SVG/Coins/Avalanche";
import MemoBitcoin from "@assets/SVG/Coins/Bitcoin";
import MemoBNB from "@assets/SVG/Coins/BNB";
import MemoBUSD from "@assets/SVG/Coins/BUSD";
import MemoCardano from "@assets/SVG/Coins/Cardano";
import MemoDAI from "@assets/SVG/Coins/DAI";
import MemoDoge from "@assets/SVG/Coins/Doge";
import MemoEthereum from "@assets/SVG/Coins/Ethereum";
import MemoPolkadot from "@assets/SVG/Coins/Polkadot";
import MemoPolygon from "@assets/SVG/Coins/Polygon";
import MemoRipple from "@assets/SVG/Coins/Ripple";
import MemoShiba from "@assets/SVG/Coins/Shiba";
import MemoSolana from "@assets/SVG/Coins/Solana";
import MemoTether from "@assets/SVG/Coins/Tether";
import MemoTron from "@assets/SVG/Coins/Tron";
import MemoUNISwap from "@assets/SVG/Coins/UNISwap";
import MemoUNUS from "@assets/SVG/Coins/UNUS";
import MemoUSDC from "@assets/SVG/Coins/USDC";
import MemoWrapper from "@assets/SVG/Coins/Wrapper";
import MemoPressRelease from "@assets/SVG/PressRelease";
import MemoGavel from "@assets/SVG/Gavel";
import MemoBlog from "@assets/SVG/Blog";
import MemoNews from "@assets/SVG/News";
import MemoLogo from "@assets/SVG/Logo/logo";
import MemoNotSure from "@assets/SVG/NotSure";
import MemoX from "@assets/SVG/X";
import MemoMedium from "@assets/SVG/Medium";
import MemoReddit from "@assets/SVG/Reddit";
import MemoYouTube from "@assets/SVG/YouTube";
import MemoPodcast from "@assets/SVG/Podcast";

/** A mapping of iconTypes to SVG Components. */
export const iconTypeToSVG = {
    "Press Release": MemoPressRelease,
    Blog: MemoBlog,
    News: MemoNews,
    BlockBeat: MemoLogo,
    Government: MemoGavel,
    "Government Press Release": MemoGavel, // TODO: remove this
    Twitter: MemoX,
    Medium: MemoMedium,
    Reddit: MemoReddit,
    "Not Sure": MemoNotSure,
    YouTube: MemoYouTube,
    Podcast: MemoPodcast,
};

export const allAssets = [
    {
        icon: MemoBitcoin,
        name: "Bitcoin",
        acronym: "BTC",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "120B",
    },
    {
        icon: MemoEthereum,
        name: "Ethereum",
        acronym: "ETH",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "373B",
    },
    {
        icon: MemoTether,
        name: "Tether",
        acronym: "USDT",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "162B",
    },
    {
        icon: MemoUSDC,
        name: "USD Coin",
        acronym: "USDC",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "68.64B",
    },
    {
        icon: MemoBNB,
        name: "BNB",
        acronym: "BNB",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "45.44B",
    },
    {
        icon: MemoRipple,
        name: "Ripple",
        acronym: "XRP",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "24.78B",
    },
    {
        icon: MemoBUSD,
        name: "Binace USD",
        acronym: "BUSD",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "24.78B",
    },
    {
        icon: MemoBitcoin,
        name: "Label",
        acronym: "LUNA",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "23.56B",
    },
    {
        icon: MemoCardano,
        name: "Cardano",
        acronym: "ADA",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "22.12B",
    },
    {
        icon: MemoSolana,
        name: "Solana",
        acronym: "SOL",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "12.33B",
    },
    {
        icon: MemoDoge,
        name: "Dogecoin",
        acronym: "DOGE",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "11.33B",
    },
    {
        icon: MemoPolygon,
        name: "Polygon",
        acronym: "MATIC",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "8.44B",
    },
    {
        icon: MemoPolkadot,
        name: "Polkadot",
        acronym: "DOT",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "7.87B",
    },
    {
        icon: MemoDAI,
        name: "Dai",
        acronym: "DAI",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "7.12B",
    },
    {
        icon: MemoTron,
        name: "TRON",
        acronym: "TRX",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "7.67B",
    },
    {
        icon: MemoShiba,
        name: "Shiba Inu",
        acronym: "SHIB",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "6.11B",
    },
    {
        icon: MemoUNISwap,
        name: "Uniswap",
        acronym: "UNI",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "6.05B",
    },
    {
        icon: MemoWrapper,
        name: "Wrapper Btc",
        acronym: "WBTC",
        last: "$ 23,042.44",
        change: "+2.81%",
        marketCap: "4.79B",
    },
    {
        icon: MemoAvalanche,
        name: "Avalanche",
        acronym: "AVAX",
        last: "$ 19.471.02",
        change: "+5.36%",
        marketCap: "4.73B",
    },
    {
        icon: MemoUNUS,
        name: "UNUS SED LEO",
        acronym: "LEO",
        last: "$ 16.06",
        change: "+7.23%",
        marketCap: "4.75B",
    },
];

export const currencyList = [
    {
        icon: MemoBitcoin,
        name: "Bitcoin",
        number: "4356123478906073",
    },
    {
        icon: MemoEthereum,
        name: "Ethereum",
        number: "0.04569103 ETH",
    },
    {
        icon: MemoDoge,
        name: "Doge",
        number: "1134.99 DOGE",
    },
    {
        icon: MemoRipple,
        name: "Ripple",
        number: "26.34 XRP",
    },
    {
        icon: MemoCardano,
        name: "Cardano",
        number: "27.98 ADA",
    },
    {
        icon: MemoAvalanche,
        name: "Avalanche",
        number: "126.23 AVAX",
    },
    {
        icon: MemoBNB,
        name: "Binance",
        number: "126.23 AVAX",
    },
    {
        icon: MemoShiba,
        name: "Shiba",
        number: "124.84 SHIB",
    },
    {
        icon: MemoSolana,
        name: "Solana",
        number: "459.35 SOL",
    },
];
