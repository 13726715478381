import { SVGProps, memo } from "react";

function BUSD(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#prefix__clip0_536_17364)">
                <circle cx={8} cy={8} r={8} fill="#EDF0F4" />
                <path
                    d="M7.97 3.333l1.235 1.264-3.11 3.11-1.236-1.235 3.11-3.14zM9.844 5.208l1.236 1.264-4.986 4.986-1.235-1.236 4.985-5.014zM4.22 7.083l1.235 1.264-1.236 1.236-1.235-1.236L4.22 7.083zM11.72 7.083l1.235 1.264-4.986 4.986-1.235-1.236 4.986-5.014z"
                    fill="#F0B90B"
                    stroke="#F0B90B"
                    strokeWidth={0.667}
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_536_17364">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoBUSD = memo(BUSD);
export default MemoBUSD;
