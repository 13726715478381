import { SVGProps, memo } from "react";

function USDC(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M8 16c4.433 0 8-3.567 8-8s-3.567-8-8-8a7.98 7.98 0 00-8 8c0 4.433 3.567 8 8 8z"
                fill="#2775CA"
            />
            <path
                d="M10.201 9.266c0-1.166-.7-1.566-2.1-1.733-1-.133-1.2-.4-1.2-.867 0-.466.333-.766 1-.766.6 0 .933.2 1.1.7a.25.25 0 00.233.166h.534c.133 0 .233-.1.233-.233V6.5a1.665 1.665 0 00-1.5-1.367v-.8c0-.133-.1-.233-.267-.267h-.5c-.133 0-.233.1-.266.267V5.1c-1 .133-1.634.8-1.634 1.633 0 1.1.667 1.533 2.067 1.7.933.167 1.233.367 1.233.9s-.466.9-1.1.9c-.866 0-1.166-.367-1.266-.867-.034-.133-.134-.2-.234-.2h-.566c-.134 0-.234.1-.234.234v.033c.134.833.667 1.433 1.767 1.6v.8c0 .133.1.233.267.267h.5c.133 0 .233-.1.266-.267v-.8c1-.167 1.667-.867 1.667-1.767z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.334 6.367c-.967 2.566.367 5.466 2.967 6.4.1.066.2.2.2.3v.466c0 .067 0 .1-.034.133-.033.134-.166.2-.3.134a5.997 5.997 0 01-3.9-3.9c-1-3.167.734-6.533 3.9-7.533.034-.034.1-.034.134-.034.133.034.2.134.2.267v.467c0 .166-.067.266-.2.333a4.93 4.93 0 00-2.967 2.967zm6.2-3.867c.033-.133.166-.2.3-.133 1.833.6 3.3 2.033 3.9 3.933 1 3.167-.733 6.533-3.9 7.533-.034.034-.1.034-.134.034-.133-.034-.2-.134-.2-.267v-.467c0-.166.067-.266.2-.333a4.931 4.931 0 002.967-2.967c.967-2.566-.367-5.466-2.967-6.4-.1-.066-.2-.2-.2-.333v-.467c0-.066 0-.1.034-.133z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoUSDC = memo(USDC);
export default MemoUSDC;
