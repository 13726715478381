import { SVGProps, memo } from "react";

function News(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <rect width={24} height={24} rx={12} fill="#B019F7" />
            <path
                d="M7.962 10.173h2.307v3.13H7.962v-3.13zM19.5 7.043v10.434a.477.477 0 01-.072.254.542.542 0 01-.202.189.622.622 0 01-.563.026l-2.048-.932-2.048.932a.669.669 0 01-.519 0L12 17.014l-2.048.932a.653.653 0 01-.52 0l-2.047-.932-2.048.932a.628.628 0 01-.563-.026.542.542 0 01-.202-.19.477.477 0 01-.072-.253V7.043c0-.276.122-.542.338-.737.216-.196.51-.306.816-.306h12.692c.306 0 .6.11.816.306a.995.995 0 01.338.737zm-8.077 2.609a.498.498 0 00-.169-.37.61.61 0 00-.408-.152H7.385a.61.61 0 00-.408.153.498.498 0 00-.17.369v4.173c0 .138.061.271.17.369a.61.61 0 00.408.153h3.461a.61.61 0 00.408-.153.498.498 0 00.17-.37V9.653zm5.77 3.13a.498.498 0 00-.17-.37.61.61 0 00-.408-.152h-3.461a.61.61 0 00-.408.153.498.498 0 00-.17.369c0 .138.062.27.17.368a.61.61 0 00.408.153h3.461a.61.61 0 00.408-.153.498.498 0 00.17-.368zm0-2.087a.498.498 0 00-.17-.369.61.61 0 00-.408-.153h-3.461a.61.61 0 00-.408.153.498.498 0 00-.17.369c0 .138.062.271.17.369a.609.609 0 00.408.153h3.461c.153 0 .3-.055.408-.153a.498.498 0 00.17-.369z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoNews = memo(News);
export default MemoNews;
