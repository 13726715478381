import { SVGProps, memo } from "react";

function Polygon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx={8} cy={8} r={8} fill="#EDF0F4" />
            <path
                d="M10.401 6.393a.617.617 0 00-.585 0l-1.365.81-.926.515-1.34.81a.617.617 0 01-.585 0l-1.048-.639a.599.599 0 01-.292-.515V6.148c0-.196.097-.393.292-.515L5.6 5.019a.617.617 0 01.585 0l1.048.638c.17.098.292.295.292.515v.81l.926-.54v-.834a.606.606 0 00-.292-.515L6.21 3.94a.617.617 0 00-.584 0L3.626 5.118a.54.54 0 00-.292.49v2.306c0 .196.097.393.292.515L5.6 9.582c.17.098.39.098.585 0l1.34-.785.926-.54 1.34-.785a.617.617 0 01.586 0l1.047.614c.171.098.293.294.293.515v1.226a.606.606 0 01-.293.515l-1.023.614a.617.617 0 01-.585 0l-1.048-.614a.599.599 0 01-.292-.515v-.785l-.926.54v.81c0 .196.097.392.292.515l1.974 1.153c.17.098.39.098.585 0l1.974-1.153a.599.599 0 00.292-.515v-2.33a.606.606 0 00-.292-.516L10.4 6.393z"
                fill="#8247E5"
            />
        </svg>
    );
}

const MemoPolygon = memo(Polygon);
export default MemoPolygon;
