import { SVGProps, memo } from "react";

function Bitcoin(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M15.76 9.935A7.998 7.998 0 11.243 6.065a7.997 7.997 0 1115.519 3.87z" fill="#F7931A" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.529 6.86c.159-1.064-.652-1.637-1.76-2.018l.36-1.443-.878-.218-.35 1.404c-.231-.058-.468-.112-.704-.166l.353-1.413-.877-.219-.36 1.442a28.995 28.995 0 01-.56-.132v-.005l-1.21-.302-.234.938s.651.15.638.158c.355.089.42.324.409.51l-.41 1.644a.713.713 0 01.092.03l-.026-.007a7.018 7.018 0 00-.067-.017L5.37 9.348c-.044.108-.154.27-.403.208.01.013-.638-.16-.638-.16l-.435 1.006 1.142.285a41.937 41.937 0 01.626.161L5.3 12.307l.876.218.36-1.443c.24.065.472.125.7.182L6.877 12.7l.878.219.363-1.456c1.497.283 2.623.17 3.097-1.185.381-1.09-.02-1.719-.807-2.129.574-.132 1.006-.51 1.121-1.289zM9.523 9.673c-.249 1-1.814.586-2.53.397l-.172-.044.482-1.933c.06.015.133.031.216.05.741.166 2.26.507 2.004 1.53zM7.668 7.247c.597.16 1.9.507 2.126-.402.232-.93-1.034-1.21-1.652-1.347a7.58 7.58 0 01-.181-.042L7.524 7.21l.144.038z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoBitcoin = memo(Bitcoin);
export default MemoBitcoin;
