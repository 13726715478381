import { SVGProps, memo } from "react";

function Wrapper(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <g clipPath="url(#prefix__clip0_536_17744)">
                <circle cx={8} cy={8} r={8} fill="#EDF0F4" />
                <circle cx={8} cy={7.999} r={8} fill="#fff" />
                <path
                    d="M12.975 3.343l-.442.442a6.25 6.25 0 010 8.44l.442.441a6.88 6.88 0 000-9.333v.01zM3.786 3.466a6.249 6.249 0 018.44 0l.441-.442a6.88 6.88 0 00-9.333 0l.452.442zM3.466 12.21a6.249 6.249 0 010-8.435l-.442-.442a6.88 6.88 0 000 9.333l.442-.456zM12.215 12.533a6.249 6.249 0 01-8.44 0l-.441.442a6.88 6.88 0 009.333 0l-.452-.442z"
                    fill="#5A5564"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.747 6.583c-.087-.918-.87-1.226-1.86-1.32V4h-.765v1.24h-.611V4h-.76v1.273H5.2v.829s.565-.01.557 0a.397.397 0 01.435.34v3.483a.272.272 0 01-.29.257c.01.009-.557 0-.557 0l-.145.925h1.538V12.4h.765v-1.274h.612v1.269h.766v-1.279c1.293-.08 2.195-.402 2.308-1.626.091-.985-.367-1.425-1.099-1.603.445-.221.72-.632.658-1.303zM9.675 9.336c0 .883-1.373.863-2 .854l-.148-.002V8.481c.053 0 .118 0 .191-.003.644-.014 1.957-.043 1.957.858zm-2.016-1.63c.525.01 1.66.03 1.66-.774 0-.824-1.1-.795-1.635-.781l-.156.003v1.55l.13.002z"
                    fill="#F09242"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 7.999a8 8 0 1016 0A8 8 0 000 8zm.632-.001a7.372 7.372 0 1114.744-.006 7.372 7.372 0 01-14.744.006z"
                    fill="#282138"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_536_17744">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoWrapper = memo(Wrapper);
export default MemoWrapper;
