import { SVGProps, memo } from "react";

function Polkadot(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx={8} cy={8} r={8} fill="#E6007A" />
            <path
                d="M8.034 2.667A4.05 4.05 0 004.213 8.01a.577.577 0 00.71.354.574.574 0 00.342-.707 2.662 2.662 0 01-.157-1.029 2.91 2.91 0 113.086 2.993s-.566.034-.848.069a2.93 2.93 0 00-.309.061.037.037 0 01-.052 0 .037.037 0 010-.045l.087-.478.532-2.392a.553.553 0 00-1.082-.232s-1.265 5.855-1.265 5.908a.532.532 0 00.394.649h.03a.531.531 0 00.65-.396v-.027c.016-.069.175-.848.175-.848a1.435 1.435 0 011.188-1.13c.122-.018.636-.053.636-.053a4.031 4.031 0 00-.296-8.041z"
                fill="#fff"
            />
            <path
                d="M8.28 11.969a.672.672 0 00-.796.52c0 .004 0 .008-.002.011a.67.67 0 00.513.797h.02a.659.659 0 00.794-.486l.002-.008v-.037a.697.697 0 00-.531-.797z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoPolkadot = memo(Polkadot);
export default MemoPolkadot;
