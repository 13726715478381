import { SVGProps, memo } from "react";

function DAI(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M8 0a8 8 0 110 15.999A8 8 0 018 0z" fill="#F5AC37" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.19 12.41v.03h-.002v.006h3.47c.393.005.784-.026 1.17-.095.406-.08.802-.205 1.18-.375.164-.076.32-.168.48-.26l.12-.07c.186-.133.365-.277.535-.43.465-.42.825-.945 1.05-1.53a.125.125 0 01.145-.095h.945c.075 0 .1-.025.1-.11v-.605a1.018 1.018 0 000-.175c0-.015.004-.03.008-.045.008-.03.015-.06-.008-.09h-.79c-.095 0-.095-.01-.095-.095a5.1 5.1 0 000-.915c-.005-.09.015-.09.085-.09h.69c.08 0 .11-.02.11-.1v-.82c-.002-.054-.003-.082-.018-.096-.015-.014-.043-.014-.101-.014h-.896a.13.13 0 01-.149-.105 4.011 4.011 0 00-.39-.765 4.818 4.818 0 00-.535-.665 4.41 4.41 0 00-.88-.68 4.887 4.887 0 00-1.565-.58 5.713 5.713 0 00-.81-.09h-3.75c-.1 0-.1.02-.1.1v2.695c0 .095-.02.095-.095.095H3.02c-.08 0-.08.014-.08.07v.88c0 .08.025.08.085.08H4.11c.08 0 .08.014.08.074v.94c0 .085-.025.085-.09.085H2.94v.95c0 .08.025.08.085.08H4.11c.08 0 .08.01.08.075V12.41zm6.904-6.055a.098.098 0 010 .07h.024c-.01.03-.06.04-.06.04h-5.77c-.094 0-.094-.02-.094-.095V4.595c0-.065.01-.095.085-.095h2.684c.286-.002.571.027.85.09a3.594 3.594 0 011.5.76c.087.064.164.14.23.225.142.14.265.296.37.465.07.1.13.205.181.315zm.24 2.21h-6.02c-.06 0-.09 0-.106-.015-.014-.015-.014-.044-.014-.1v-.89c0-.07.02-.095.095-.095h6.05c.065 0 .095.025.095.085.025.31.025.62 0 .93-.005.085-.036.085-.1.085zm-.24 1.04c-.085-.01-.17-.01-.255 0H5.294c-.075 0-.1 0-.1.1v1.735c0 .08 0 .1.1.1h2.56c.122.01.245 0 .365-.025a3.81 3.81 0 001.085-.24c.127-.044.25-.101.365-.17h.035a3.248 3.248 0 001.394-1.404s.035-.076-.004-.096z"
                fill="#FEFEFD"
            />
        </svg>
    );
}

const MemoDAI = memo(DAI);
export default MemoDAI;
