import { SVGProps, memo } from "react";

function Tether(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path d="M15.759 9.935a7.998 7.998 0 11-15.52-3.87 7.998 7.998 0 0115.52 3.87z" fill="#50AF95" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.012 8.908c-.057.004-.349.021-1 .021-.52 0-.888-.015-1.017-.021-2.004-.089-3.5-.437-3.5-.855s1.496-.766 3.5-.856V8.56c.131.01.507.032 1.025.032.622 0 .934-.026.99-.031V7.198c2 .09 3.492.438 3.492.855s-1.492.765-3.492.854h.002zm0-1.851v-1.22h2.79v-1.86H4.206v1.86h2.79v1.22C4.727 7.16 3.02 7.61 3.02 8.148c0 .539 1.706.987 3.974 1.092v3.908H9.01v-3.91c2.263-.104 3.966-.552 3.966-1.09S11.276 7.16 9.01 7.057h.001z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoTether = memo(Tether);
export default MemoTether;
