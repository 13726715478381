import { SVGProps, memo } from "react";

function Reddit(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <rect width={24} height={24} rx={12} fill={props.fill || "#FF4500"} />
            <path
                d="M19 12.176c0-.777-.627-1.405-1.4-1.405-.508 0-.952.27-1.197.678-1.014-.79-2.447-1.3-4.05-1.368l.63-3.796 2.432.61c.029.007.057.004.086.004l-.001.007c0 .582.47 1.054 1.05 1.054.58 0 1.05-.472 1.05-1.054 0-.582-.47-1.054-1.05-1.054-.344 0-.646.168-.838.424a.347.347 0 00-.127-.062l-2.8-.703a.35.35 0 00-.43.283l-.7 4.216c-.004.024.001.047.002.07-1.607.066-3.044.578-4.06 1.369A1.394 1.394 0 006.4 10.77c-.774 0-1.4.628-1.4 1.405 0 .688.49 1.258 1.141 1.38-.06.236-.091.478-.091.728 0 2.33 2.663 4.216 5.95 4.216 3.287 0 5.95-1.887 5.95-4.216 0-.25-.031-.492-.091-.727A1.402 1.402 0 0019 12.176zM8.5 13.23c0-.582.47-1.054 1.05-1.054.58 0 1.05.472 1.05 1.054 0 .582-.47 1.054-1.05 1.054-.58 0-1.05-.472-1.05-1.054zm6.166 2.985c-.721.567-1.668.88-2.666.88s-1.945-.313-2.666-.88a.352.352 0 01-.06-.493.35.35 0 01.492-.06c.598.47 1.392.73 2.234.73.842 0 1.636-.26 2.234-.73a.35.35 0 01.492.06.352.352 0 01-.06.493zm-.216-1.931c-.58 0-1.05-.472-1.05-1.054 0-.582.47-1.054 1.05-1.054.58 0 1.05.472 1.05 1.054 0 .582-.47 1.054-1.05 1.054z"
                fill={props.color || "#fff"}
            />
        </svg>
    );
}

const MemoReddit = memo(Reddit);
export default MemoReddit;
