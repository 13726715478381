import { SVGProps, memo } from "react";

function BNB(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 17 16" fill="none" {...props}>
            <path d="M16.755 9.935a7.998 7.998 0 11-15.52-3.87 7.997 7.997 0 1115.52 3.87z" fill="#F3BA2F" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.997 5.077L6.926 7.15 5.721 5.943l3.276-3.276 3.278 3.277L11.07 7.15 8.997 5.077zM4.87 6.794L3.664 8 4.87 9.205 6.075 8 4.869 6.794zm2.057 2.057l2.071 2.071L11.07 8.85l1.206 1.205-3.279 3.278-3.277-3.277-.001-.001L6.926 8.85zm6.2-2.056L11.92 8l1.205 1.206L14.331 8l-1.206-1.205z"
                fill="#fff"
            />
            <path
                d="M10.22 8L8.997 6.775l-.904.904-.104.104-.214.214L7.773 8 7.775 8l1.222 1.223L10.22 8V8"
                fill="#fff"
            />
        </svg>
    );
}

const MemoBNB = memo(BNB);
export default MemoBNB;
