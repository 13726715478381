import { SVGProps, memo } from "react";

function Medium(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <rect width={16} height={16} rx={8} fill={props.color || "#fff"} />
            <path
                d="M6.717 4.667c1.87 0 3.385 1.525 3.385 3.407 0 1.882-1.516 3.408-3.385 3.408-1.869 0-3.384-1.526-3.384-3.408s1.515-3.407 3.384-3.407zm5.405.2c.935 0 1.692 1.435 1.692 3.207 0 1.772-.757 3.208-1.692 3.208-.934 0-1.692-1.436-1.692-3.208 0-1.771.758-3.208 1.692-3.208zm2.616.333c.329 0 .595 1.287.595 2.874 0 1.587-.266 2.874-.595 2.874-.329 0-.595-1.287-.595-2.874 0-1.587.266-2.874.595-2.874z"
                fill={props.fill || "#000"}
            />
        </svg>
    );
}

const MemoMedium = memo(Medium);
export default MemoMedium;
