import { SVGProps, memo } from "react";

function Gavel(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="-30 -20 180 180"
            width="1em"
            height="1em"
            {...props}
            className={`${props.className} rounded-full`}
        >
            <rect x={-30} width={210} height={200} y={-20} fill="#b11c1c" />
            <path
                d="M97.48 26.61c3.09 3.09 3.15 8.08.14 11.1l-1.63 1.63 13.1 12.88-33.13 31.92L63.2 70.92l-49.74 49.74c-3.01 3.01-8.01 2.95-11.1-.14-3.09-3.09-3.15-8.08-.14-11.1L52.14 59.5l-9.99-10.39 31.32-31.93 11.21 11 1.72-1.72c3-3 7.99-2.94 11.08.15zM72.11 1.88c2.46 2.46 2.51 6.43.11 8.83L35.69 47.24c-2.4 2.4-6.37 2.35-8.83-.11s-2.51-6.43-.11-8.83L63.28 1.77c2.4-2.4 6.37-2.35 8.83.11zm51.93 51.93c2.46 2.46 2.51 6.43.11 8.83L87.62 99.18c-2.4 2.4-6.37 2.35-8.83-.11s-2.51-6.43-.11-8.83l36.53-36.53c2.4-2.41 6.37-2.36 8.83.1z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill={props.fill || "white"}
            />
        </svg>
    );
}

const MemoGavel = memo(Gavel);
export default MemoGavel;
