import { SVGProps, memo } from "react";

function Ethereum(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx={8} cy={8} r={8} fill="#EDF0F4" />
            <path d="M7.978 3.2l-.064.216V9.69l.064.063 2.911-1.721-2.911-4.83z" fill="#343434" />
            <path d="M7.978 3.2L5.066 8.03l2.912 1.722V3.2z" fill="#8C8C8C" />
            <path d="M7.977 10.303l-.036.044v2.234l.036.105 2.913-4.103-2.913 1.72z" fill="#3C3C3B" />
            <path d="M7.978 12.686v-2.383l-2.912-1.72 2.912 4.103z" fill="#8C8C8C" />
            <path d="M7.979 9.752l2.911-1.72-2.911-1.324v3.044z" fill="#fff" />
            <path d="M5.066 8.031l2.912 1.721V6.708L5.066 8.03z" fill="#393939" />
        </svg>
    );
}

const MemoEthereum = memo(Ethereum);
export default MemoEthereum;
