import { SVGProps, memo } from "react";

function NotSure(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <rect width={24} height={24} rx={12} fill="#FDBE00" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.29 16.244a1.095 1.095 0 00-.033 1.448l.017.018.017.017a.952.952 0 001.367-.035l4.004-3.195c-1.066-.433-1.944-1.27-2.468-2.331L6.29 16.244zm3.377-6.362c0 2.144 1.642 3.883 3.667 3.883S17 12.026 17 9.882 15.359 6 13.334 6 9.667 7.738 9.667 9.882z"
                fill="#000"
            />
        </svg>
    );
}

const MemoNotSure = memo(NotSure);
export default MemoNotSure;
