import { SVGProps, memo } from "react";

function Shiba(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 16c-4.4 0-8-3.6-8-8.04.04-4.4 3.6-8 8.04-7.96C12.44.04 16 3.6 16 8.08 15.96 12.44 12.4 16 8 16z"
                fill="#E42D04"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.155 13.866c.58 0 2.07-.262 3.383-1.309 1.51-1.204 1.652-1.875 1.743-2.308l.024-.108a1.35 1.35 0 00.027-.258 4.67 4.67 0 00-1.28-.019c-.848.1-1.515.68-1.742.956-.126-.629-.742-1.887-2.196-1.887-1.454 0-2.238 1.258-2.449 1.887-.134-.234-.636-.755-1.565-.956a2.924 2.924 0 00-1.434.042c.028.468.182.967.591 1.493.705.907 1.798 1.466 2.285 1.716l.139.071c.379.202 1.414.68 2.474.68z"
                fill="#fff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.331 9.865a4.678 4.678 0 00-1.28-.02c-.847.1-1.513.678-1.74.954-.126-.627-.742-1.882-2.195-1.882-1.454 0-2.237 1.255-2.448 1.882-.134-.234-.635-.753-1.564-.953a2.928 2.928 0 00-1.433.041c-.026-.425.053-.825.137-1.196.141-.622.496-1.447.656-1.782-.143-.343-.474-1.315-.656-2.46-.182-1.144.126-1.915.303-2.158.286.076.969.312 1.413.653.444.341 1.127 1.096 1.412 1.43.37-.1 1.323-.3 2.17-.3.848 0 1.666.2 1.968.3.253-.3.873-1.008 1.338-1.43.464-.422 1.219-.628 1.538-.678.085.109.263.427.303.828.05.502-.1 1.556-.303 2.26a53.16 53.16 0 01-.454 1.48c.042.092.187.391.43.853.256.49.422 1.594.405 2.178z"
                fill="#FFAA35"
            />
            <path
                d="M9.19 12.098c-.06.08-.124.234-.15.3v-.024l-.074-.602c-.142.016-.51.05-.85.05-.34 0-.742-.034-.9-.05l-.025.602a1.843 1.843 0 01-.1-.176c-.05-.1-.225-.25-.225-.326 0-.06-.083-.192-.125-.25l-.075-.101h.45l.1.075c.083-.008.295-.015.475.025s.292-.117.325-.2v-.402a3 3 0 00-.4-.226c-.175-.075-.35-.376-.3-.677.05-.301.2-.402.3-.376.1.025.75-.026.85 0 .1.025.275.075.35.2.075.126.025.628-.125.803-.12.14-.35.243-.45.276v.402c0 .05.175.175.2.2.025.025.4 0 .475 0 .06 0 .108-.05.125-.075h.425v.1c0 .05-.075.276-.1.301-.025.025-.1.05-.175.15z"
                fill="#fff"
            />
            <path
                d="M8.387 11.932l-.254.2-.203-.2-.178.09-.152-.156h1.066l-.152.178-.127-.112zM5.653 7.83a4.694 4.694 0 00-.744-.23c-.033.017-.1.071-.1.153 0 .103-.024.205 0 .41.026.206.224.334.571.488a3.93 3.93 0 001.364.282c.421 0 .025-.436-.124-.539-.149-.102-.595-.41-.967-.564zM6.061 7.15c-.222.04-.53.05-.656.05-.127-.017-.395-.095-.455-.274-.076-.224.126-.472.379-.596a.604.604 0 01.783.223c.202.299.227.547-.05.597zM10.611 7.697c.298-.123.62-.205.744-.231.033.017.1.072.1.154 0 .102.024.205 0 .41-.025.205-.224.333-.571.487A3.93 3.93 0 019.52 8.8c-.421 0-.024-.436.124-.538.15-.103.595-.41.967-.564zM9.805 7.15c.222.04.53.05.657.05.126-.017.394-.095.455-.274.076-.224-.127-.472-.38-.596a.604.604 0 00-.783.223c-.202.299-.227.547.051.597z"
                fill="#fff"
            />
            <path
                d="M4.323 5.29c-.182.198-.53.644-.681.843a5.393 5.393 0 01-.404-1.365c-.08-.575-.017-1.463.025-1.835.11.008.43.1.833.397.404.297.993.851 1.236 1.091-.26.207-.827.67-1.009.868z"
                fill="#FF8C30"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.2 3.405c.396.166.918.42 1.137.648.222.232.418.54.534.746.174-.144.347-.283.461-.373-.246-.24-.842-.796-1.25-1.095-.408-.299-.731-.39-.842-.398a10.19 10.19 0 00-.04.472z"
                fill="#FF9A32"
            />
            <path
                d="M11.543 5.29c.181.198.53.644.68.843.102-.215.324-.79.405-1.365.08-.575.017-1.463-.026-1.835-.109.008-.428.1-.832.397-.404.297-.993.851-1.237 1.091.261.207.828.67 1.01.868z"
                fill="#FF8C30"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.666 3.405c-.396.166-.919.42-1.138.648-.222.232-.417.54-.533.746a24.279 24.279 0 00-.462-.373c.247-.24.842-.796 1.25-1.095.409-.299.732-.39.843-.398.013.116.028.282.04.472z"
                fill="#FF9A32"
            />
        </svg>
    );
}

const MemoShiba = memo(Shiba);
export default MemoShiba;
