import { SVGProps, memo } from "react";

function PressRelease(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <rect width={24} height={24} rx={12} fill="#7219F7" />
            <path
                d="M14.98 7.206a14.334 14.334 0 01-5.353 1.026h-.465a3.256 3.256 0 00-.546 6.466c.208.85.495 1.667.855 2.445.288.622 1.038.818 1.602.493l.407-.235c.545-.315.723-.988.479-1.53a10.61 10.61 0 01-.39-.997c1.19.16 2.333.465 3.411.896.45-1.345.694-2.785.694-4.282 0-1.497-.243-2.937-.694-4.282zM15.836 6.83c.498 1.462.769 3.028.769 4.658a14.401 14.401 0 01-.875 4.957.465.465 0 10.874.32 15.246 15.246 0 00.878-3.988c.32-.334.518-.789.518-1.289 0-.5-.198-.955-.518-1.289a15.29 15.29 0 00-.878-3.987.465.465 0 00-.874.32c.037.099.072.198.106.299z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoPressRelease = memo(PressRelease);
export default MemoPressRelease;
