import { SVGProps, memo } from "react";

function Avalanche(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx={8} cy={8} r={8} fill="#E84142" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.479 2.375c-.278-.48-.719-.48-.996 0L2.45 11.238c-.277.485-.05.869.504.869H5.48a1.6 1.6 0 001.247-.73L9.77 6.103c.22-.454.22-.99 0-1.443l-.907-1.593-.384-.693zm3.344 5.832c-.277-.478-.724-.478-1.001 0l-1.758 3.03c-.27.479-.044.87.504.87h3.477c.555 0 .781-.391.504-.87l-1.726-3.03z"
                fill="#fff"
            />
        </svg>
    );
}

const MemoAvalanche = memo(Avalanche);
export default MemoAvalanche;
